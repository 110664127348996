/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

import IptcTagNames from './iptc-tag-names.js';
import TagDecoder from './tag-decoder.js';
const BYTES_8BIM = 0x3842494d;
const BYTES_8BIM_SIZE = 4;
const RESOURCE_BLOCK_HEADER_SIZE = BYTES_8BIM_SIZE + 8;
const NAA_RESOURCE_BLOCK_TYPE = 0x0404;
const TAG_HEADER_SIZE = 5;
export default {
  read
};
function read(dataView, dataOffset, includeUnknown) {
  try {
    if (Array.isArray(dataView)) {
      return parseTags(new DataView(Uint8Array.from(dataView).buffer), {
        size: dataView.length
      }, 0, includeUnknown);
    }
    const {
      naaBlock,
      dataOffset: newDataOffset
    } = getNaaResourceBlock(dataView, dataOffset);
    return parseTags(dataView, naaBlock, newDataOffset, includeUnknown);
  } catch (error) {
    return {};
  }
}
function getNaaResourceBlock(dataView, dataOffset) {
  while (dataOffset + RESOURCE_BLOCK_HEADER_SIZE <= dataView.byteLength) {
    const resourceBlock = getResourceBlock(dataView, dataOffset);
    if (isNaaResourceBlock(resourceBlock)) {
      return {
        naaBlock: resourceBlock,
        dataOffset: dataOffset + RESOURCE_BLOCK_HEADER_SIZE
      };
    }
    dataOffset += RESOURCE_BLOCK_HEADER_SIZE + resourceBlock.size + getBlockPadding(resourceBlock);
  }
  throw new Error('No IPTC NAA resource block.');
}
function getResourceBlock(dataView, dataOffset) {
  const RESOURCE_BLOCK_SIZE_OFFSET = 10;
  if (dataView.getUint32(dataOffset, false) !== BYTES_8BIM) {
    throw new Error('Not an IPTC resource block.');
  }
  return {
    type: dataView.getUint16(dataOffset + BYTES_8BIM_SIZE),
    size: dataView.getUint16(dataOffset + RESOURCE_BLOCK_SIZE_OFFSET)
  };
}
function isNaaResourceBlock(resourceBlock) {
  return resourceBlock.type === NAA_RESOURCE_BLOCK_TYPE;
}
function getBlockPadding(resourceBlock) {
  if (resourceBlock.size % 2 !== 0) {
    return 1;
  }
  return 0;
}
function parseTags(dataView, naaBlock, dataOffset, includeUnknown) {
  const tags = {};
  let encoding = undefined;
  const endOfBlockOffset = dataOffset + naaBlock['size'];
  while (dataOffset < endOfBlockOffset && dataOffset < dataView.byteLength) {
    const {
      tag,
      tagSize
    } = readTag(dataView, dataOffset, tags, encoding, includeUnknown);
    if (tag === null) {
      break;
    }
    if (tag) {
      if ('encoding' in tag) {
        encoding = tag.encoding;
      }
      if (tags[tag.name] === undefined || tag['repeatable'] === undefined) {
        tags[tag.name] = {
          id: tag.id,
          value: tag.value,
          description: tag.description
        };
      } else {
        if (!(tags[tag.name] instanceof Array)) {
          tags[tag.name] = [{
            id: tags[tag.name].id,
            value: tags[tag.name].value,
            description: tags[tag.name].description
          }];
        }
        tags[tag.name].push({
          id: tag.id,
          value: tag.value,
          description: tag.description
        });
      }
    }
    dataOffset += TAG_HEADER_SIZE + tagSize;
  }
  return tags;
}
function readTag(dataView, dataOffset, tags, encoding, includeUnknown) {
  const TAG_CODE_OFFSET = 1;
  const TAG_SIZE_OFFSET = 3;
  if (leadByteIsMissing(dataView, dataOffset)) {
    return {
      tag: null,
      tagSize: 0
    };
  }
  const tagCode = dataView.getUint16(dataOffset + TAG_CODE_OFFSET);
  const tagSize = dataView.getUint16(dataOffset + TAG_SIZE_OFFSET);
  if (!includeUnknown && !IptcTagNames['iptc'][tagCode]) {
    return {
      tag: undefined,
      tagSize
    };
  }
  const tagValue = getTagValue(dataView, dataOffset + TAG_HEADER_SIZE, tagSize);
  const tag = {
    id: tagCode,
    name: getTagName(IptcTagNames['iptc'][tagCode], tagCode, tagValue),
    value: tagValue,
    description: getTagDescription(IptcTagNames['iptc'][tagCode], tagValue, tags, encoding)
  };
  if (tagIsRepeatable(tagCode)) {
    tag['repeatable'] = true;
  }
  if (tagContainsEncoding(tagCode)) {
    tag['encoding'] = IptcTagNames['iptc'][tagCode]['encoding_name'](tagValue);
  }
  return {
    tag,
    tagSize
  };
}
function leadByteIsMissing(dataView, dataOffset) {
  const TAG_LEAD_BYTE = 0x1c;
  return dataView.getUint8(dataOffset) !== TAG_LEAD_BYTE;
}
function getTagValue(dataView, offset, size) {
  const value = [];
  for (let valueIndex = 0; valueIndex < size; valueIndex++) {
    value.push(dataView.getUint8(offset + valueIndex));
  }
  return value;
}
function getTagName(tag, tagCode, tagValue) {
  if (!tag) {
    return `undefined-${tagCode}`;
  }
  if (tagIsName(tag)) {
    return tag;
  }
  if (hasDynamicName(tag)) {
    return tag['name'](tagValue);
  }
  return tag['name'];
}
function tagIsName(tag) {
  return typeof tag === 'string';
}
function hasDynamicName(tag) {
  return typeof tag['name'] === 'function';
}
function getTagDescription(tag, tagValue, tags, encoding) {
  if (hasDescriptionProperty(tag)) {
    try {
      return tag['description'](tagValue, tags);
    } catch (error) {
      // Fall through to next handler.
    }
  }
  if (tagValueIsText(tag, tagValue)) {
    return TagDecoder.decode(encoding, tagValue);
  }
  return tagValue;
}
function tagValueIsText(tag, tagValue) {
  return tag && tagValue instanceof Array;
}
function hasDescriptionProperty(tag) {
  return tag && tag['description'] !== undefined;
}
function tagIsRepeatable(tagCode) {
  return IptcTagNames['iptc'][tagCode] && IptcTagNames['iptc'][tagCode]['repeatable'];
}
function tagContainsEncoding(tagCode) {
  return IptcTagNames['iptc'][tagCode] && IptcTagNames['iptc'][tagCode]['encoding_name'] !== undefined;
}