/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

import Types from './types.js';
export default {
  read
};
function read(dataView, fileDataOffset) {
  return {
    'Image Width': getImageWidth(dataView, fileDataOffset),
    'Image Height': getImageHeight(dataView, fileDataOffset),
    'Bit Depth': getBitDepth(dataView, fileDataOffset),
    'Color Type': getColorType(dataView, fileDataOffset),
    'Compression': getCompression(dataView, fileDataOffset),
    'Filter': getFilter(dataView, fileDataOffset),
    'Interlace': getInterlace(dataView, fileDataOffset)
  };
}
function getImageWidth(dataView, fileDataOffset) {
  const OFFSET = 0;
  const SIZE = 4;
  if (fileDataOffset + OFFSET + SIZE > dataView.byteLength) {
    return undefined;
  }
  const value = Types.getLongAt(dataView, fileDataOffset);
  return {
    value,
    description: `${value}px`
  };
}
function getImageHeight(dataView, fileDataOffset) {
  const OFFSET = 4;
  const SIZE = 4;
  if (fileDataOffset + OFFSET + SIZE > dataView.byteLength) {
    return undefined;
  }
  const value = Types.getLongAt(dataView, fileDataOffset + OFFSET);
  return {
    value,
    description: `${value}px`
  };
}
function getBitDepth(dataView, fileDataOffset) {
  const OFFSET = 8;
  const SIZE = 1;
  if (fileDataOffset + OFFSET + SIZE > dataView.byteLength) {
    return undefined;
  }
  const value = Types.getByteAt(dataView, fileDataOffset + OFFSET);
  return {
    value,
    description: `${value}`
  };
}
function getColorType(dataView, fileDataOffset) {
  const OFFSET = 9;
  const SIZE = 1;
  const COLOR_TYPES = {
    0: 'Grayscale',
    2: 'RGB',
    3: 'Palette',
    4: 'Grayscale with Alpha',
    6: 'RGB with Alpha'
  };
  if (fileDataOffset + OFFSET + SIZE > dataView.byteLength) {
    return undefined;
  }
  const value = Types.getByteAt(dataView, fileDataOffset + OFFSET);
  return {
    value,
    description: COLOR_TYPES[value] || 'Unknown'
  };
}
function getCompression(dataView, fileDataOffset) {
  const OFFSET = 10;
  const SIZE = 1;
  if (fileDataOffset + OFFSET + SIZE > dataView.byteLength) {
    return undefined;
  }
  const value = Types.getByteAt(dataView, fileDataOffset + OFFSET);
  return {
    value,
    description: value === 0 ? 'Deflate/Inflate' : 'Unknown'
  };
}
function getFilter(dataView, fileDataOffset) {
  const OFFSET = 11;
  const SIZE = 1;
  if (fileDataOffset + OFFSET + SIZE > dataView.byteLength) {
    return undefined;
  }
  const value = Types.getByteAt(dataView, fileDataOffset + OFFSET);
  return {
    value,
    description: value === 0 ? 'Adaptive' : 'Unknown'
  };
}
function getInterlace(dataView, fileDataOffset) {
  const OFFSET = 12;
  const SIZE = 1;
  const INTERLACE_TYPES = {
    0: 'Noninterlaced',
    1: 'Adam7 Interlace'
  };
  if (fileDataOffset + OFFSET + SIZE > dataView.byteLength) {
    return undefined;
  }
  const value = Types.getByteAt(dataView, fileDataOffset + OFFSET);
  return {
    value,
    description: INTERLACE_TYPES[value] || 'Unknown'
  };
}