/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

export function getStringValue(value) {
  return value.map(charCode => String.fromCharCode(charCode)).join('');
}
export function getEncodedString(value) {
  if (value.length >= 8) {
    const encoding = getStringValue(value.slice(0, 8));
    if (encoding === 'ASCII\x00\x00\x00') {
      return getStringValue(value.slice(8));
    } else if (encoding === 'JIS\x00\x00\x00\x00\x00') {
      return '[JIS encoded text]';
    } else if (encoding === 'UNICODE\x00') {
      return '[Unicode encoded text]';
    } else if (encoding === '\x00\x00\x00\x00\x00\x00\x00\x00') {
      return '[Undefined encoding]';
    }
  }
  return 'Undefined';
}
export function getCalculatedGpsValue(value) {
  return value[0][0] / value[0][1] + value[1][0] / value[1][1] / 60 + value[2][0] / value[2][1] / 3600;
}